<template>
	<div>
		<div class="text-h6 font-weight-bold mb-2">
			<span style="word-break: normal">{{ event.title }}</span>
			<EventInfoUpdateForm :loading-parent="loadingParent || loading" />
		</div>
		<p class="text-subtitle-2" style="word-break: normal">
			<v-icon class="mr-1">mdi-domain</v-icon>
			<template v-if="event.companyName">
				<i18n path="offers.managedBy" tag="span">
					<template #name>
						<span style="color: #FF6F00">{{ event.companyName }}</span>
					</template>
					<template #parent>
						<router-link class="text-subtitle-2 text-decoration-none red--text" :to="{ name: 'Company', params: { id: event.company.id } }">
							{{ event.company.name }}
						</router-link>
					</template>
				</i18n>
			</template>
			<template v-else>
				<router-link
					class="text-subtitle-2 text-decoration-none red--text"
					style="color: #FF6F00"
					:to="{ name: 'Company', params: { id: event.company.id } }"
				>
					{{ event.company.name }}
				</router-link>
			</template>
		</p>
		<p>
			<span style="color: orange; word-break: normal">{{ event.type.name }}</span> <v-icon>mdi-map-marker</v-icon> {{ event.info.location }}
		</p>
		<p>
			<span><v-icon>mdi-calendar</v-icon> {{ humanTime(event.info.timestamp.start) }}</span>
			<span> - </span>
			<span>{{ humanTime(event.info.timestamp.end) }}</span>
		</p>
		<p><v-icon>mdi-earth</v-icon> {{ event.info.website }}</p>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'EventInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		EventInfoUpdateForm: () => import('@/components/events/EventInfoUpdateForm.vue')
	},
	computed: {
		...mapGetters({
			event: 'events/event'
		})
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss')
		}
	}
}
</script>
